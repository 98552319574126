<template>
  <alert-form />
</template>

<script>
  import AlertForm from './form'
  export default {
    components: { AlertForm },
  }
</script>
